import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VueI18n from "vue-i18n";
import { Ripple } from "vuetify/lib/directives";
import dateTimeFormats from "@/languages/dateTimeFormats";
import lang_en from "@/languages/en.json";

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});
Vue.use(VueI18n);

const i18n = new VueI18n({
  dateTimeFormats,
  locale: "en",
  messages: {
    en: lang_en
  }
});

const lang = {
  t: (key, ...params) => i18n.t(key, params)
};

export default new Vuetify({
  lang,
  theme: {
    themes: {
      light: {
        primary: "#006940",
        secondary: "#f3c97e",
        accent: "#9cc3b2",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
});
