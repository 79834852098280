import { getField, updateField } from "vuex-map-fields";

const defaultState = () => {
  return {
    wizard: {
      billingAgree: false,
      quick_feed_type: "",
      feed_class: "",
      selectedComponents: [],
      showCalibrate: false,
      showCutting: false,
      currentStep: 1,
      returnStep: false, // Next step override
      steps: [
        {
          title: "Sample Information",
          component: "SampleInformationStep"
        },
        {
          title: "Imaging",
          component: "ImagingStep"
        },
        {
          title: "Packages and Components",
          component: "PackageSelectionStep"
        },
        {
          title: "Billing",
          component: "BillingStep"
        },
        {
          title: "Review and Edit",
          component: "ReviewStep"
        },
        {
          title: "Shipping",
          component: "ShipmentSelectionStep"
        }
      ],
      error: {
        message: ""
      }
    }
  };
};

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getField,
    steps(state) {
      return state.wizard.steps;
    },
    stepId(state, getters) {
      return component_name => {
        let indexhere = getters.steps.findIndex(
          step => step.component === component_name
        );

        // findIndex returns -1 if not found
        if (indexhere === -1) {
          indexhere = getters.steps.length + 1;
        }

        return indexhere;
      };
    },
    currentStepComponentTitle(state) {
      return state.wizard.steps[state.wizard.currentStep - 1].component;
    }
  },
  mutations: {
    updateField,
    initializeState(state) {
      Object.assign(state, defaultState());
    },
    setError(state, message = "") {
      state.wizard.error.message = message;
    },
    resetWizard(state) {
      Object.assign(state, defaultState());
    }
  }
};
