import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";
import pick from "lodash.pick";
import isEmpty from "lodash.isempty";
import { validId } from "@/utils";
import {
  createSample,
  createSampleCalibrate
} from "@/store/modules/samples/create-sample";
import { getPaginated } from "@/utils/api-utils";

// Sets of fields to update. Utilized by updateSampleSet action.
const sampleSets = {
  initial: {
    fields: [
      "client_id",
      "lab_id",
      "shipments",
      "copy_to",
      "is_active",
      "adjusted_test_components",
      "adjusted_test_packages",
      "farm_id",
      "farms_source_id",
      "feed_type_id",
      "description",
      "is_preground",
      "is_fresh",
      "is_sample_bag",
      "grind_size",
      "sample_bag_barcode",
      "dry_matter_method",
      "grind_method",
      "date_collected",
      "date_harvested",
      "cutting",
      "is_linked",
      "linked_samples",
      "source_other",
      "sampler_name",
      "is_certified",
      "certified_protocol",
      "sampling_device",
      "number_of_cores_bales",
      "estimated_dry_matter",
      "gv_file_number",
      "gv_sales_rep_name",
      "gv_sales_rep_number",
      "wallensteins_req_id"
    ]
  },
  package: {
    fields: [
      "test_packages",
      "test_components",
      "is_calibrate",
      "sample_calibrate",
      "is_best_mix",
      "best_mix_farm_id",
      "best_mix_request_id",
      "is_grand_valley",
      "grand_valley_file_number",
      "grand_valley_dairy_specialist"
    ]
  },
  billing: {
    fields: ["copy_to"]
  }
};

const hasShipment = sample =>
  sample.shipments &&
  sample.shipments.length &&
  validId(sample.shipments[0].id);

const emptyStats = () => ({
  pending: 0,
  in_transit: 0,
  reported_today: 0,
  average_transit_days: null,
  average_nir_return_days: null
});

export default {
  namespaced: true,
  state: {
    items: [],
    // Active Sample so this doesn't get mixed with items from Sample Browse
    active_sample: {},
    billingAccount: {
      title: "",
      first_name: "",
      last_name: "",
      work_phone: "",
      address_1: "",
      address_2: "",
      city: "",
      state_id: "",
      country_id: "",
      postal_code: ""
    },
    stats: emptyStats(),
    error: {}
  },
  getters: {
    getField,
    filterById(state, getters) {
      /**
       * Return items filtered by ID
       * @param {string} getter - The getter you're plucking an item from
       * @param {int} id - The ID of the item you want returned
       * @param {string} idField (optional) - The field used when filtering by ID
       */
      return (getter, id, idField = "id") => {
        return getters[getter].filter(item => item[idField] === id) || [];
      };
    },
    getById(state) {
      /**
       * Return an item by ID
       * @param {int} id - The ID of the item you want returned
       * @param {string} idField (optional) - The field used when filtering by ID
       */
      return (id, idField = "id") => {
        return state.items.find(item => item[idField] === id) || {};
      };
    },
    sortedItems(state) {
      return [...state.items].sort((a, b) => {
        if (a[state.id] === b[state.id]) return 0;
        return a[state.id] > b[state.id] ? 1 : -1;
      });
    },
    adjusted_test_components(state) {
      return state.adjusted_test_components;
    },
    adjusted_test_packages(state) {
      return state.adjusted_test_packages;
    },
    firstEmail(state) {
      return state.billingAccount &&
        state.billingAccount.clients_email_addresses &&
        state.billingAccount.clients_email_addresses.length > 0 &&
        Object.hasOwn(
          state.billingAccount.clients_email_addresses[0],
          "email_address"
        )
        ? state.billingAccount.clients_email_addresses[0].email_address
        : "";
    }
  },
  mutations: {
    updateField,
    setItems(state, payload) {
      Vue.set(state, "items", payload);
    },
    setItem(state, payload) {
      const itemIndex = state.items.findIndex(item => item.id === payload.id);
      if (itemIndex === -1) {
        Vue.set(state, "items", [...state.items, { ...payload }]);
      } else {
        Vue.set(state.items, itemIndex, payload);
      }
      // If the updated sample is the active sample, update that state as well
      if (payload.id === state.active_sample.id) {
        Vue.set(state, "active_sample", {
          ...state.active_sample,
          ...payload
        });
      }
    },
    setActive(state, payload = null) {
      if (payload === null) payload = createSample();
      // Only retain the ID for each of these hasMany relation objects
      ["copy_to", "linked_samples", "test_packages"].map(item => {
        if (payload[item] && payload[item].length) {
          payload[item] = payload[item].map(i => pick(i, ["id"]));
        }
      });
      // Creat the sample calibrate object, if it doesnt exist
      if (isEmpty(payload.sample_calibrate))
        payload.sample_calibrate = createSampleCalibrate();
      // And set the final object
      Vue.set(state, "active_sample", payload);
    },
    clearActive(state) {
      Vue.set(state, "active_sample", {});
    },
    setBillingAccount(state, payload) {
      Vue.set(state, "billingAccount", payload);
    },
    setActiveSampleCopyTos(state, copyToArray) {
      Vue.set(
        state.active_sample,
        "copy_to",
        Array.isArray(copyToArray)
          ? copyToArray.map(item => {
              return { id: item };
            })
          : []
      );
    },
    setActiveSampleCopyTo(state, payload) {
      let copyTos = state.active_sample.copy_to;
      let copyTo = copyTos.find(o => o.id === payload);
      // only push if it doesnt already exist
      if (!copyTo) {
        copyTos.push({ id: payload });
        Vue.set(state.active_sample, "copy_to", copyTos);
      }
    },
    updateActiveSample(state, payload) {
      Vue.set(state, "active_sample", {
        ...state.active_sample,
        ...payload
      });
    },
    setAdjustedTestComponents(state, payload) {
      Vue.set(state, "adjusted_test_components", payload);
    },
    setAdjustedTestPackages(state, payload) {
      Vue.set(state, "adjusted_test_packages", payload);
    },
    resetStats(state) {
      state.stats = emptyStats();
    },
    setStats(state, payload) {
      Object.assign(state.stats, payload);
    }
  },
  actions: {
    getPaginated: getPaginated("samples"),
    getStats({ commit }) {
      commit("resetStats");
      return Vue.axios
        .get("/samples/stats")
        .then(({ data }) => {
          if (data.success && data.data) {
            commit("setStats", data.data);
          }
          return data.success;
        })
        .catch(() => {
          return false;
        });
    },
    setActive({ commit, dispatch, rootGetters }, id = null) {
      let getSample =
        id !== null && validId(id)
          ? dispatch("getSamples", id)
          : createSample({
              client_id: rootGetters["clients/selectedClient"].id,
              lab_id: rootGetters["clients/selectedClient"].lab_id
            });

      return Promise.resolve(getSample)
        .then(sample => {
          commit("setActive", sample);
          return sample;
        })
        .catch(e => {
          console.error("Error fetching active sample - ", e);
          return false;
        });
    },
    addSample({ state, dispatch, commit }) {
      const shipment = function() {
        if (hasShipment(state.active_sample)) {
          return Promise.resolve(true);
        } else {
          return dispatch(
            "shipments/getUnshipped",
            { lab_id: state.active_sample.lab_id },
            { root: true }
          ).then(res => {
            if (!res) return false;

            if (
              typeof res.data.data !== "undefined" &&
              res.data.data.length > 0 &&
              typeof res.data.data[0] !== "undefined"
            ) {
              commit("updateActiveSample", {
                shipments: [
                  {
                    id: res.data.data[0].id,
                    lab_id: state.active_sample.lab_id
                  }
                ]
              });
            } else {
              return dispatch(
                "shipments/createShipment",
                { lab_id: state.active_sample.lab_id },
                {
                  root: true
                }
              ).then(res => {
                if (!res) return false;
                commit("updateActiveSample", { shipments: [{ ...res }] });
                return true;
              });
            }
          });
        }
      };
      return shipment() // Call the shipment function
        .then(() => dispatch("updateSampleSet", "initial"))
        .catch(e => {
          console.error("Error updating sample", e);
          return false;
        })
        .then(data => {
          if (!data) {
            console.error(data);
            return false;
          }
          // Update the active sample with the response data (ID)
          commit("updateActiveSample", data);
          return true;
        });
    },
    getSamples({ commit }, id = undefined) {
      return Vue.axios
        .get("/samples" + (validId(id) ? `/${id}` : ""))
        .then(({ data }) => {
          if (!data.success) {
            console.warn("Error loading sample(s) - ", data);
            return false;
          }
          if (validId(id)) {
            commit("setItem", data.data);
            return data.data;
          } else {
            commit("setItems", data.data);
          }
        });
    },
    updateSample({ commit }, payload) {
      return Vue.axios[validId(payload.id) ? "put" : "post"](
        `/samples/${validId(payload.id) ? payload.id : ""}`,
        payload
      ).then(({ data }) => {
        if (!data.success) return false;
        // Update local item(s)
        commit("setItem", payload);
        return data.data;
      });
    },
    deleteSampleImage(store, payload) {
      return Vue.axios["delete"](`/sample-images/${payload.id}`).then(
        ({ data }) => {
          return data.success;
        }
      );
    },
    updateSampleSet({ state, dispatch }, set = undefined) {
      if (!set || sampleSets[set] === undefined) {
        console.error("Set not found - ", set);
        return false;
      }

      return dispatch(
        "updateSample",
        pick(state.active_sample, ["id", ...sampleSets[set].fields])
      );
    },
    getBillingAccount({ commit }, id) {
      return Vue.axios
        .get("/clients/validate-billing-account?search=" + id)
        .then(({ data }) => {
          commit(
            "setBillingAccount",
            pick(data.data, [
              "title",
              "first_name",
              "last_name",
              "work_phone",
              "fax",
              "clients_email_addresses",
              "address_1",
              "address_2",
              "city",
              "state_id",
              "country_id",
              "postal_code"
            ])
          );
        })
        .catch(e => {
          console.error(e);
          return false;
        });
    },
    getBillingInformation({ rootGetters, dispatch, commit, state }) {
      return new Promise((resolve, reject) => {
        const client = rootGetters["clients/selectedClient"];
        // If this user doesn't have a client, there is no billing information to gather
        if (!client.id) reject("Client information not found");
        // If this is a billing_only_account, display error that this account cannot submit samples.
        if (client.billing_only_client)
          reject(
            "Cannot submit samples. This is a billing only client account."
          );

        // If already agreed to billing, no need to get billing again
        if (state.billingAgree) resolve(true);

        // If client is using third party billing
        if (client.third_party_billing) {
          // Check that billing account has approved this client
          if (
            (typeof client.clients_third_party_billing === "object" &&
              client.clients_third_party_billing === null) ||
            !client.clients_third_party_billing.approved
          )
            reject(
              "Third party billing account has not approved this billing account yet."
            );
          // Request the billing account
          resolve(
            dispatch(
              "getBillingAccount",
              client.clients_third_party_billing.billing_only_account_number
            )
          );
        } else {
          if (client.clients_addresses) {
            const billingAddress = client.clients_addresses.find(
              address => address.clients_address_type_id === 1 // 1 = Billing
            );

            commit("setBillingAccount", {
              ...pick(client, [
                "title",
                "first_name",
                "last_name",
                "work_phone",
                "fax",
                "clients_email_addresses"
              ]),
              ...billingAddress
            });

            resolve(true);
          }
        }
      });
    },
    updateActiveSample({ commit }, selectedSamples) {
      commit("updateActiveSample", {
        sample_test_components: selectedSamples
      });
      return true;
    },
    getFile(context, payload) {
      return Vue.axios
        .get(payload.url + "?" + payload.data, {
          transformHeader: payload.type
        })
        .then(response => {
          let blob = new Blob([response.data], { type: payload.type }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        });
    },
    getSampleTestComponents({ state, commit }) {
      return Vue.axios
        .get(`/test-components`, {
          params: {
            lab_id: state.active_sample.lab_id,
            client_id: state.active_sample.client_id
          }
        })
        .then(res => {
          commit("setAdjustedTestComponents", res.data.data);
          const { data } = res;
          return data.success || false;
        })
        .catch(e => {
          console.error(e);
          return false;
        });
    }
  }
};
