<template>
  <v-form ref="registerForm" v-model="valid">
    <LoadingBar :hide="!loading" :error="error" />
    <v-card v-show="!loading" class="px-4">
      <v-stepper v-model="currentStep" vertical>
        <v-stepper-step step="1" :complete="currentStep >= 1">
          Account Credentials
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card color="" class="mb-5">
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  label="Email"
                  v-model="username"
                  :rules="rules.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Password"
                  type="password"
                  v-model="password"
                  :rules="rules.password"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Password (confirm)"
                  type="password"
                  v-model="password_confirm"
                  v-on:keyup.enter="currentStep = !valid ? currentStep : 2"
                  :rules="rules.password_confirm"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
          <v-btn :disabled="!valid" color="primary" @click="currentStep = 2">
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="2" :complete="currentStep >= 2">
          Account Details
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card v-if="currentStep === 2" class="mb-5">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Business Name"
                  v-model="business_name"
                  :rules="rules.businessOrNames"
                  v-on:keyup.enter="currentStep = !valid ? currentStep : 3"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="First Name"
                  v-model="first_name"
                  :rules="rules.businessOrFirstName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Last Name"
                  v-model="last_name"
                  :rules="rules.businessOrLastName"
                  v-on:keyup.enter="currentStep = !valid ? currentStep : 3"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
          <v-btn :disabled="!valid" color="primary" @click="currentStep = 3">
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="3" :complete="currentStep >= 3">
          Billing Terms
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card v-if="currentStep === 3" class="mb-5">
            <v-row>
              <v-col cols="12" class="text-justify">
                <v-dialog
                  v-model="billing_terms_dialog"
                  scrollable
                  max-width="580px"
                  min-width="320px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                      View Billing Terms
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title
                      >The billing information and terms are listed
                      below</v-card-title
                    >
                    <v-divider></v-divider>
                    <v-card-text style="height: 340px;" class="pt-4">
                      <p>
                        Selecting this agreement is required to complete the
                        online client account.
                      </p>
                      <p>
                        Please note that our billing terms are net 30.
                      </p>
                      <p>
                        We charge an annual late charge of 18% on any balance 30
                        days past due.
                      </p>
                      <p>
                        Bills are generated around the 1st and 15th of each
                        month.
                      </p>
                      <p>
                        PLEASE PAY FROM THE INVOICE. Statements are generated on
                        the 28th of each month ONLY if there is a past due
                        balance. If you have billing questions or concerns
                        please contact us at accounting@foragelab.com. If my
                        account is not paid in a timely manner and it becomes
                        necessary to refer my account to a Collection Agency, I
                        understand that I will be responsible for all collection
                        agency fees to include reasonable attorney fees and
                        court costs.
                      </p>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        text
                        @click="
                          agree_terms = true;
                          billing_terms_dialog = false;
                        "
                      >
                        Accept Terms
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-checkbox
                  label="I agree to the terms of payment as a client."
                  v-model="agree_terms"
                  :rules="rules.agree_terms"
                  class="ml-3"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
      </v-stepper>

      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn
          x-large
          block
          :disabled="!canRegister"
          color="success"
          @click="submit"
        >
          Register
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";
import rules from "@/utils/rules";

/**
 * @property {string} username
 * @property {string} password
 * @property {string} business_name
 * @property {string} first_name
 * @property {string} last_name
 * @property {boolean} agree_terms
 * @property {boolean|string} error
 */
export default {
  name: "Register",
  components: { LoadingBar },
  data() {
    return {
      loading: false,
      currentStep: 1,
      valid: false,
      password_confirm: "",
      agree_terms: false,
      billing_terms_dialog: false
    };
  },
  computed: {
    ...mapFields("auth", [
      "username",
      "password",
      "registration.first_name",
      "registration.last_name",
      "registration.business_name",
      "registration.error",
      "activeTab"
    ]),
    rules() {
      let componentRules = {
        required: [rules.required],
        email: [rules.required, rules.email],
        password: [rules.required, rules.minLength(8)],
        password_confirm: [
          rules.required,
          v => v === this.password || "Passwords do not match"
        ]
      };

      const businessOrName = field => {
        return () => {
          return (
            !!this.business_name ||
            !!field ||
            "Business Name or First/Last Name are required"
          );
        };
      };

      const businessOrNames = () => {
        return (
          !!this.business_name ||
          !!this.first_name ||
          !!this.last_name ||
          "Business Name or First/Last Name are required"
        );
      };

      const must_agree = () => {
        return (
          !!this.agree_terms || "You must agree to payment terms to continue."
        );
      };

      // Separating these into individual componentRules, that way current field with a value won't show validation errors
      componentRules.businessOrFirstName = [businessOrName(this.first_name)];
      componentRules.businessOrLastName = [businessOrName(this.last_name)];
      componentRules.businessOrNames = [businessOrNames];
      componentRules.agree_terms = [must_agree];

      return componentRules;
    },
    canRegister() {
      return this.currentStep === 3 && this.valid && this.loading !== true;
    }
  },
  watch: {
    password() {
      this.validate();
    },
    password_confirm() {
      this.validate();
    },
    business_name() {
      this.validate();
    },
    last_name() {
      this.validate();
    }
  },
  methods: {
    ...mapActions("auth", ["submitRegistration", "login"]),
    submit() {
      if (this.validate()) {
        this.loading = true;
        this.submitRegistration()
          .then(res => {
            if (res) {
              return this.login().then(() => {
                this.activeTab = 0;
                this.loading = false;
              });
            } else {
              this.loading = false;
            }
          })
          .catch(e => {
            this.currentStep = 1;
            this.loading = false;
            console.error("error", e);
          });
      }
    },
    validate() {
      return this.$refs.registerForm.validate();
    }
  }
};
</script>

<style scoped></style>
