<template>
  <div v-if="isLoading" class="mt-5">
    <v-progress-linear indeterminate />
  </div>
  <v-card v-else class="mt-5">
    <v-card-text>
      <v-form>
        <v-row dense>
          <v-col>
            <v-select
              label="Farm"
              :items="farms"
              v-model="options.params.farm_id"
              item-text="title"
              item-value="id"
              no-data-text="No farms to select from"
              :disabled="isChartLoading"
              clearable
            />
          </v-col>
          <v-col>
            <v-select
              label="Feed Source"
              v-model="options.params.farm_source_id"
              :items="options.params.farm_id ? sources : []"
              item-text="title"
              item-value="id"
              no-data-text="No feed sources to select from"
              :disabled="isChartLoading"
              clearable
            />
          </v-col>
          <v-col>
            <v-autocomplete
              label="Feed Type"
              v-model="options.params.feed_type_id"
              :items="feed_types"
              item-text="title"
              item-value="id"
              no-data-text="No feed types to select from"
              :disabled="isChartLoading"
              clearable
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <DatePickerInput
              :default-value="options.params.from_date"
              :datePickerConfig="{
                label: 'From Date',
                field: 'from_date'
              }"
              @updateDateField="
                (field, val) => {
                  options.params.from_date = val;
                }
              "
              :clearable="false"
            ></DatePickerInput>
          </v-col>
          <v-col>
            <DatePickerInput
              :default-value="options.params.to_date"
              :date-picker-config="{
                label: 'To Date',
                field: 'to_date'
              }"
              @updateDateField="
                (field, val) => {
                  options.params.to_date = val;
                }
              "
              :clearable="false"
            ></DatePickerInput>
          </v-col>
          <v-col>
            <v-autocomplete
              label="Component"
              v-model="options.params.test_component_id"
              :items="test_components"
              item-text="title"
              item-value="id"
              :disabled="isChartLoading"
              clearable
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <div v-if="isChartLoading">
      <v-progress-linear indeterminate />
    </div>
    <template v-else>
      <v-card-text class="py-0">
        <b>Feed Type</b> {{ feedTypeTitle }}
      </v-card-text>
      <v-card-text class="py-0"> <b># of Samples</b> {{ count }} </v-card-text>
      <v-card-text class="py-0">
        <b>Average</b> {{ Math.round(mean * 100) / 100 }}
      </v-card-text>
      <v-card-text class="py-0">
        <b>Standard Deviation</b> {{ Math.round(sd * 100) / 100 }}
      </v-card-text>
      <GChart
        type="ColumnChart"
        :data="chartData"
        :options="chartOptions"
        :create-chart="
          (el, google, type) => {
            this.chart = new google.visualization[type](el);
            return this.chart;
          }
        "
      />
      <v-card-text class="text-right">
        <v-btn
          icon
          v-if="chartImageUrl"
          :href="chartImageUrl"
          :download="chartOptions.title"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";
import { mapActions, mapGetters, mapState } from "vuex";
import DatePickerInput from "@/components/formElements/DatePickerInput";
import dayjs from "dayjs";

export default {
  name: "DashGraph",
  components: { GChart, DatePickerInput },
  data: () => ({
    isLoading: false,
    isChartLoading: false,
    options: {
      itemsPerPage: 100000,
      params: {
        farm_id: null,
        farm_source_id: null,
        feed_type_id: null,
        test_component_id: null,
        from_date: null,
        to_date: null
      }
    },
    chart: null
  }),
  watch: {
    "options.params.farm_id"(farmId) {
      if (farmId) {
        this.isChartLoading = true;
        this.getFarmSources(farmId).finally(() => {
          this.getResults();
        });
      } else this.getResults();
    },
    "options.params.farm_source_id"(farmsSourceId) {
      if (farmsSourceId) {
        this.isChartLoading = true;
        this.getFarmSource({
          farm_id: this.options.params.farm_id,
          source_id: farmsSourceId
        }).finally(() => {
          if (this.options.params.feed_type_id !== this.farmSource.feed_type_id)
            this.options.params.feed_type_id = this.farmSource.feed_type_id;
          else this.getResults();
        });
      } else this.getResults();
    },
    "options.params.feed_type_id"() {
      this.getResults();
    },
    "options.params.test_component_id"() {
      this.getResults();
    },
    "options.params.from_date"() {
      this.getResults();
    },
    "options.params.to_date"() {
      this.getResults();
    }
  },
  computed: {
    ...mapGetters("data_review", ["chartData", "count", "mean", "sd"]),
    ...mapGetters("baseData", ["feed_types", "test_components"]),
    ...mapGetters("sources", { sources: "getActiveItems" }),
    ...mapGetters("farms", { farms: "getActiveItems" }),
    ...mapState("sources", { farmSource: "detailItem" }),
    componentTitle() {
      if (!this.options.params.test_component_id) return "All Components";
      return this.test_components.find(
        item => item.id === this.options.params.test_component_id
      ).title;
    },
    feedTypeTitle() {
      if (!this.options.params.feed_type_id) return "All Types";
      return this.feed_types.find(
        item => item.id === this.options.params.feed_type_id
      ).title;
    },
    chartHeight() {
      return this.$vuetify.breakpoint.xsOnly ? 300 : 400;
    },
    chartOptions() {
      return {
        title: "Chart for " + this.componentTitle,
        colors: ["rgb(44, 92, 61)", "rgb(92, 141, 116)"],
        height: this.chartHeight,
        series: {
          0: { targetAxisIndex: 0 },
          1: { targetAxisIndex: 1 }
        },
        vAxes: {
          0: { title: "Percent of samples" },
          1: { title: "Number of samples" }
        }
      };
    },
    chartImageUrl() {
      if (!this.chart) return "";
      return this.chart.getImageURI();
    }
  },
  methods: {
    ...mapActions("farms", ["getFarms"]),
    ...mapActions("sources", ["getFarmSources", "getFarmSource"]),
    ...mapActions("baseData", ["getFeedTypes", "getTestComponents"]),
    ...mapActions("data_review", ["getSampleComponentMethodResults"]),
    async getResults() {
      if (this.isLoading) return;
      this.isChartLoading = true;
      await this.getSampleComponentMethodResults(this.options);
      this.isChartLoading = false;
      localStorage.setItem(
        "googleChartParams",
        JSON.stringify(this.options.params)
      );
    }
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.getFarms(),
      this.getFeedTypes(),
      this.getTestComponents()
    ]);
    const params = localStorage.getItem("googleChartParams");
    if (params) {
      this.options.params = JSON.parse(params);
    } else {
      if (!this.options.params.feed_type_id)
        this.options.params.feed_type_id =
          this.feed_types.find(item => item.title === "Corn Silage").id || null;
      if (!this.options.params.test_component_id)
        this.options.params.test_component_id =
          this.test_components.find(item => item.title === "Starch, %DM").id ||
          null;
    }
    let oneYear = new Date();
    oneYear.setFullYear(oneYear.getFullYear() - 1);
    this.options.params.from_date = dayjs(oneYear).format("YYYY-MM-DD");
    this.options.params.to_date = dayjs().format("YYYY-MM-DD");
    this.isLoading = false;
  }
};
</script>

<style scoped></style>
