import Vue from "vue";

import auth from "@websanova/vue-auth/src/v2.js";
import driverHttpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";
import driverJwt from "./drivers/jwt";

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router
  },
  drivers: {
    auth: driverJwt,
    http: driverHttpAxios,
    router: driverRouterVueRouter
  },
  options: {
    rolesKey: "roles",
    loginData: { url: "jwt/login" },
    refreshData: { url: "jwt/refresh" },
    logoutData: { url: "jwt/logout", redirect: "/login" },
    fetchData: { url: "users/me" },
    refreshTokenName: "auth_token_refresh",
    forbiddenRedirect: function() {
      // Force "new" users to the Account Setup route
      if (Vue.auth.check("new")) {
        return "/setup";
      }
      return "/403";
    }
  }
});

export default {};
