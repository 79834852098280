import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";
import { getPaginated } from "@/utils/api-utils";

const farm = function() {
  return {
    id: null,
    title: "",
    number: "",
    address_1: "",
    address_2: "",
    city: "",
    country_id: "",
    state_id: "",
    postal_code: "",
    phone: "",
    email: "",
    latitude: "",
    longitude: "",
    is_active: true
  };
};

export default {
  namespaced: true,
  state: {
    items: [],
    detailItem: farm(),
    error: {}
  },
  getters: {
    getField,
    getById(state) {
      /**
       * Return an item by ID
       * @param {int} id - The ID of the item you want returned
       * @param {string} idField (optional) - The field used when filtering by ID
       */
      return (id, idField = "id") =>
        state.items.find(item => item[idField] === id) || {};
    },
    getActiveItems(state) {
      return state.items.filter(item => item.is_active);
    }
  },
  mutations: {
    updateField,
    setItems(state, payload) {
      Vue.set(state, "items", payload);
    },
    setItem(state, payload) {
      const item = state.items.find(item => item.id === payload.id);
      if (item) {
        Object.assign(item, payload);
      }
    },
    setDetailItem(state, payload) {
      Vue.set(state, "detailItem", payload);
    },
    clearDetailItem(state) {
      Vue.set(state, "detailItem", farm());
    },
    addItem(state, item) {
      state.items.push(item);
    }
  },
  actions: {
    getPaginated: getPaginated("/farms"),
    addFarm(context, payload) {
      return Vue.axios.post("/farms", payload).then(({ data }) => {
        context.commit("addItem", Object.assign(payload, data.data));
        return data.success || false;
      });
    },
    editFarm(context, payload) {
      return Vue.axios.put(`/farms/${payload.id}`, payload).then(({ data }) => {
        context.commit("setItem", payload);
        return data.success || false;
      });
    },
    getFarms({ commit, dispatch }) {
      return dispatch("getPaginated").then(({ data }) => {
        commit("setItems", data.data);
        return data.success || false;
      });
    },
    getFarm({ commit }, id) {
      return Vue.axios.get(`/farms/${id}`).then(({ data }) => {
        commit("setDetailItem", data.data);
        return data.success || false;
      });
    }
  }
};
