<template>
  <v-snackbar
    v-model="active"
    :timeout="notifications.snackbar.timeout"
    v-bind="notifications.snackbar.attrs"
  >
    <p class="mb-0 text-body-1">{{ notifications.snackbar.message }}</p>

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="active = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";

export default {
  name: "SnackBar",
  props: ["timeout"],
  computed: {
    ...mapFields(["notifications.snackbar.active"]),
    ...mapState(["notifications"])
  }
};
</script>
